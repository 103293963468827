import { motion } from 'framer-motion';

const variants = {
    initial: {
        x: '100vw'
    },
    visible: {
        x: '0',
        transition: { duration: 0.75 }
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut', duration: 0.5 }
    }
}

function AnimatePage({children}){
    return (
        <motion.div 
            variants={variants}
            initial="initial"
            animate="visible"
            exit="exit" 
            >
            {children}
        </motion.div>
    )
}

export default AnimatePage;