import { Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

import AnimatePage from '../components/animatePage';
import Content from '../content/services.json';
import services1 from '../resources/services1.jpg';
import services2 from '../resources/services2.jpg';
import services3 from '../resources/services3.jpg';
import services4 from '../resources/services4.jpg';
import services5 from '../resources/services5.jpg';
import services6 from '../resources/services6.jpg';

const images = {
    services1,
    services2,
    services3,
    services4,
    services5,
    services6
}

export default function Services() {
    return (
        <AnimatePage>
            <>
                <Helmet>
                    <title>{Content.metaTitle}</title>
                    <meta
                        name="description"
                        content={Content.metaDescription}
                    />
                </Helmet>
                <div className="container">
                    <h2 className="heading">{Content.title}</h2>
                    <p className="heading__description">{Content.description}</p>
                    <div className="services">
                        {Content.children.map((child, index) => {
                            const { icon = "", title = "", description = "" } = child;
                            const isEvenIndex = index % 2 === 0
                            return (
                                <Row className="services__item align-items-center" key={title}>
                                    {isEvenIndex ?
                                        <>
                                            <Col sm="12" lg="4" className="text-right">
                                                {title && <h3>{title}</h3>}
                                                {description && <p>{description}</p>}
                                            </Col>
                                            <Col sm="12" lg="8" className="box-shadow__light">
                                                <img className="image" src={images[icon]} alt={`icon for ${title}`} />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col sm="12" lg="8" className="box-shadow__light">
                                                <img className="image" src={images[icon]} alt={`icon for ${title}`} />
                                            </Col>
                                            <Col sm="12" lg="4" className="text-left">
                                                {title && <h3>{title}</h3>}
                                                {description && <p>{description}</p>}
                                            </Col>
                                        </>
                                    }
                                </Row>
                            )
                        })}
                    </div>
                </div>
            </>
        </AnimatePage>
    )
}
