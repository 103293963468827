import { Fragment } from "react";
import {
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import Helmet from "react-helmet";

import AnimatePage from "../components/animatePage";
import Content from "../content/careers.json";

// Function to get the week number of the month
const getWeekOfMonth = (date) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const dayOfWeek = firstDay.getDay() - 1;
  const daysSinceBeginning = date.getDate() + dayOfWeek;
  return Math.ceil(daysSinceBeginning / 7);
};

// Function to get the number of items based on the week of the month
const getItemsBasedOnWeek = (list, currentDate) => {
  const dayOfWeek = currentDate.getDay();
  const shuffledList = list;
  const weekNumber = getWeekOfMonth(currentDate);

  switch (weekNumber) {
    case 1:
      return shuffledList.slice(0, 6);
    case 2:
      return shuffledList.slice(0, 4);
    case 3:
      return shuffledList.slice(0, 4);
    case 4:
      return shuffledList.slice(0, 6);
    case 5:
      // Check if there is a fifth week in the month
      return shuffledList.slice(0, 5);
    default:
      return [];
  }
};

// Example usage
const currentDate = new Date(); // Use the current date

export default function Careers() {
  return (
    <AnimatePage>
      <>
        <Helmet>
          <title>{Content.metaTitle}</title>
          <meta name="description" content={Content.metaDescription} />
        </Helmet>
        <div className="container">
          <h2 className="heading">{Content.title}</h2>
          <p className="heading__description">{Content.description}</p>
          <div className="text-left">
            <UncontrolledAccordion defaultActiveKey stayOpen>
              {getItemsBasedOnWeek(Content.children, currentDate).map(
                (child, inx) => {
                  const {
                    jobId = "",
                    title = "",
                    description = "",
                    requirements = "",
                  } = child;
                  return (
                    <Fragment key={jobId}>
                      <AccordionItem className="careers-item">
                        <AccordionHeader targetId={jobId}>
                          {title}
                        </AccordionHeader>
                        <AccordionBody accordionId={jobId}>
                          <div className="careers-item__description">
                            {description}
                          </div>
                          <div>
                            Requirements:
                            <ul>
                              {requirements.map((requirement) => {
                                return (
                                  <li key={requirement.jobId}>{requirement}</li>
                                );
                              })}
                            </ul>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    </Fragment>
                  );
                }
              )}
            </UncontrolledAccordion>
          </div>
        </div>
      </>
    </AnimatePage>
  );
}
