import Helmet from 'react-helmet';

import AnimatePage from '../components/animatePage';
import Content from '../content/about.json';

export default function About() {
    return (
        <AnimatePage>
            <>
                <Helmet>
                    <title>{Content.metaTitle}</title>
                    <meta
                        name="description"
                        content={Content.metaDescription}
                    />
                </Helmet>
                <div className="container">
                    <h2 className="heading">{Content.title}</h2>
                    <p className="heading__description">{Content.description}</p>
                    <div className="about-items text-left">
                        <p className="description">{Content.description2}</p>
                        {Content.children.map(child => {
                            const { title = "", description = "" } = child;
                            return (
                                <div className="about-item" key={title}>
                                    <h3>
                                        {title}
                                    </h3>
                                    <p>
                                        {description}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        </AnimatePage>
    )
}
