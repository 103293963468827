import 'bootstrap/dist/css/bootstrap.css';
import './styles/app.scss';

import CorporateSite from './corporateSite/app';

function App() {
  return (
    <div className="App">
      <CorporateSite />
    </div>
  );
}

export default App;
