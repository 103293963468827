import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";

import NavigationMenu from './components/navigationMenu';

import Home from './routes/home';
import About from './routes/about';
import Team from './routes/team';
import Services from './routes/services';
import Careers from './routes/careers';
import Contact from './routes/contact';
import Error from './routes/error';
import Footer from './routes/footer';

import './styles/app.scss';

function App() {
    const location = useLocation();
    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            <Routes location={location} key={location.pathname}>
                <Route path="/about" element={<About />} />
                <Route path="/team" element={<Team />} />
                <Route path="/services" element={<Services />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Error />} />
            </Routes>
        </AnimatePresence>
    )
}

function CorporateSite() {
    return (
        <div className="app-container">
            <div className="app-content">
                <BrowserRouter>
                    <NavigationMenu />
                    <App />
                </BrowserRouter>
            </div>
            <div className="app-footer">
                <Footer />
            </div>
        </div>
    )
}

export default CorporateSite;