import Helmet from 'react-helmet';

import AnimatePage from '../components/animatePage';
import Content from '../content/team.json';

export default function Team() {
    return (
        <AnimatePage>
            <>
                <Helmet>
                    <title>{Content.metaTitle}</title>
                    <meta
                        name="description"
                        content={Content.metaDescription}
                    />
                </Helmet>
                <div className="container">
                    <h2 className="heading">{Content.title}</h2>
                    <p className="heading__description">{Content.description}</p>
                </div>
            </>
        </AnimatePage>
    )
}
