import { Row, Col } from 'reactstrap';

import NavigationItems from '../content/navigationItems';
import FooterContent from '../content/footer.json';

export default function Footer() {
    const Content = FooterContent.children; 
    return (
        <footer className="footer">
            <div className="footer__details">
                <div className="container">
                    <Row className="footer__details__child first justify-content-around">
                        <Col sm="12" md="6" lg="5" className="item about">
                            <div className="header">{Content[0]["title"]}</div>
                            <div className="body">{Content[0]["description"]}</div>
                        </Col>
                        <Col sm="12" md="6" lg="5" className="item navigation">
                            <div className="header">{Content[1]["title"]}</div>
                            <div className="body">
                                <ul>
                                    {NavigationItems.map(navItem => {
                                        const { id, label } = navItem;
                                        return (
                                            <li  key={id}>
                                                <a
                                                    href={`${window.location.origin}/${id}`}
                                                    >
                                                    {label}
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className="footer__details__child justify-content-around second">
                        <Col sm="12" md="6" lg="5" className="item corporate-enquiry">
                            <div className="header">
                                {Content[2]["title"]}
                            </div>
                            <div className="body">
                                {Content[2]["children"].map(item => {
                                    const {title="", description=""} = item;
                                    return(
                                        <div key={title}>
                                            <div>{title}</div>
                                            <div>{description}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Col>
                        <Col sm="12" md="6" lg="5" className="item">
                            <div className="header">
                                {Content[3]["title"]}
                            </div>
                            <div className="body">
                                {Content[3]["children"].map(item => <div key={item["description"]}>{item["description"]}</div>)}
                            </div>                        
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="footer__copyright">
                <div className="container">
                    Copyright 2021 TekOhana. All Rights Reserved.
                </div>
            </div>
        </footer>
    )
}