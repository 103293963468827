import { Link, useLocation } from 'react-router-dom';
import Logo from '../resources/logo.jpg'
import NavigationItems from '../content/navigationItems';

export const LinkItem = ({navItem, pathName}) => {
	const { id, label } = navItem;
	return (
		<li>
			<Link 
				to={`/${id}`}
				className={`animated animated-border-bottom ${pathName===`/${id}` ? ' active' : ''}`}>
				{label}
			</Link>
		</li>
	)
}

function NavigationMenu() {
	const location = useLocation();
	const pathName = location.pathname;
	const isHomeRoute = pathName === '/';

	return (
		<header>
			<div className={`navigation-menu-desktop ${isHomeRoute ? '' : 'non-home-page'}`}>
			<div className="container">
				<nav className="navigation-menu__nav row justify-content-between align-items-center">
					<div className="navigation-menu__home-link-wrapper col-sm-12 col-md-6 col-lg-5">
						<Link className="navigation-menu__home-link" to="/">
							<div className="navigation-menu__home-link__logo">
								<img src={Logo} alt="TekOhana Logo"/>
							</div>
							<div className="navigation-menu__home-link__wrapper">
								<span className="navigation-menu__home-link__1">Tek</span>
								<span className="navigation-menu__home-link__2">Ohana</span>
							</div>
						</Link>
					</div>
					<div className="navigation-menu__page-links col-sm-12 col-md-6 col-lg-5">
						<ul className="grid justify-content-end align-items-center">
							{NavigationItems.map(navItem => <LinkItem key={navItem.id} navItem={navItem} pathName={pathName} />)}			
						</ul>
					</div>
				</nav>
			</div>
			</div>
		</header>
	)
}

export default NavigationMenu;