import { Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

import AnimatePage from '../components/animatePage';
import Content from '../content/contact.json';

export default function Contact() {
    return (
        <AnimatePage>
            <>
                <Helmet>
                    <title>{Content.metaTitle}</title>
                    <meta
                        name="description"
                        content={Content.metaDescription}
                    />
                </Helmet>
                <div className="contact__container">
                    <div className="container">
                        <div className="">
                            <h2 className="heading">{Content.title}</h2>
                            <p className="heading__description">{Content.description}</p>
                        </div>
                        <Row className="contact">
                            {Content.children.map(child => {
                                const { title = "", description = "" } = child;
                                return (
                                    <Col key={title} sm="12" md="6" lg="4" className="contact__item box-shadow__light flex-column align-items gap-1x">
                                        <div className="text">
                                            {title && <h3>{title}</h3>}
                                            {description && <p>{description}</p>}
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>
            </>
        </AnimatePage>
    )
}
