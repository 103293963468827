import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

import AnimatePage from '../components/animatePage';
import Image from '../resources/home.jpg';
import Content from '../content/home.json';

function Home() {
	return (
		<AnimatePage>
			<>
				<Helmet>
					<title>{Content.metaTitle}</title>
					<meta
						name="description"
						content={Content.metaDescription}
					/>
				</Helmet>
				<div className="container">
					<div className="home__container">
						<Row className="home__body">
							<Col sm="12" md="6" lg="5" className="home__body__item home__body__1">
								<img src={Image} alt="ima" ></img>
							</Col>
							<Col sm="12" md="6" lg="7" className="home__body__item home__body__2">
								<span className="text">{Content.title}</span>
								<Link to={"/services"} className="link animated">{Content.description}</Link>
							</Col>
						</Row>
					</div>
				</div>
			</>
		</AnimatePage>
	)
}

export default Home;